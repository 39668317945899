import { toggleClass } from '../../packs/shared/util';

function updateField(inputElement, required) {
  const fieldElement = inputElement.closest('.field');

  if (!fieldElement || !fieldElement.classList.contains('field')) {
    return;
  }

  toggleClass(fieldElement, 'is-required', required);
}

export default () => {
  setImmediate(() => {
    document.querySelectorAll('*[required="required"], *[required="true"], select.choices[required="required"], select.choices[required="true"]').forEach((inputElement) => {
      updateField(inputElement, true)
    });

    document.querySelectorAll('input, select, textarea').forEach((inputElement) => {
      inputElement.addEventListener('required', () => {
        const required = inputElement.hasAttribute('required')

        updateField(inputElement, required);
      })
    })
  })
}
