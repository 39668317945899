export default () => {
  const anchors = document.querySelectorAll('a')

  anchors.forEach((anchor) => {
    // prevents default event from firing on empty anchors, disabled anchors
    anchor.addEventListener('click', (e) => {
      if (anchor.getAttribute('href') === '#' || anchor.classList.contains('disabled')) {
        e.preventDefault();
      }
    })
  })
}
