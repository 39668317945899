export default () => {
  document.querySelectorAll('input[data-validation-percentage]').forEach((input) => {
    input.addEventListener('change', (e) => {
      if (e.target.value < 0) {
        e.target.value = 0;
      } else if (e.target.value > 100) {
        e.target.value = 100;
      }
    });
  })
}
