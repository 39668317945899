import Rails from '@rails/ujs'
import { default as init } from '../bulma/init';
import { default as initShared } from '../shared/init'


Rails.start()

document.addEventListener('DOMContentLoaded', () => {
  initShared()
  init()
})
