export default () => {
  function setAttributes(meter) {
    meter.setAttribute('min', 0);
    meter.setAttribute('low', 0);
    const max = Number(meter.attributes.getNamedItem('max')?.value);
    const value = Number(meter.attributes.getNamedItem('value')?.value);

    if (!Number.isNaN(max) && !Number.isNaN(value)) {
      if (value === max) {
        meter.setAttribute('high', max);
      } else {
        meter.setAttribute('high', max - 1e-10);
      }
    }
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && (mutation.attributeName === 'max' || mutation.attributeName === 'value')) {
        setAttributes(mutation.target);
      }
    });
  });

  document.querySelectorAll('meter.meter').forEach((element) => {
    observer.observe(element, { attributes: true });
    setAttributes(element);
  });
}
