export default () => {
  document.querySelectorAll('[data-integer-input="true"]').forEach((input) => {
    input.addEventListener('change', () => {
      input.value = parseInt(input.value, 10);
    });
  });

  document.querySelectorAll('input[type="number"]').forEach((input) => {
    input.addEventListener('change', (event) => {
      let number = Number(input.value);
      const min = Number(input.attributes.getNamedItem('min')?.value);
      const max = Number(input.attributes.getNamedItem('max')?.value);

      event.stopPropagation();

      if (Number.isNaN(number)) {
        input.value = null;

        return true;
      }

      if (!Number.isNaN(min)) {
        number = Math.max(number, min);
      }

      if (!Number.isNaN(max)) {
        number = Math.min(number, max);
      }

      input.value = number;

      return true;
    });
  });
}
