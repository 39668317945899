export default () => {
  document.querySelectorAll('input:not([type="number"]):not([type="date"])').forEach((input) => {
    input.addEventListener('change', () => {
      const max = input.attributes.getNamedItem('max');

      if (max) {
        input.value = input.value.substring(0, max.value)
      }
    })
  });
};
