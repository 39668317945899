import fieldErrors from './field-errors';
import inputHours from './input-hours';
import inputNumeric from './input-numeric';
import inputPercentage from './input-percentage';
import inputRequired from './input-required';
import inputTextMinMax from './input-text-min-max';
import meters from './meters';
import select from './select';
import select2 from './select2';

export default () => {
  fieldErrors();
  select();
  inputPercentage();
  select2();
  inputTextMinMax();
  inputNumeric();
  meters();
  inputHours();
  inputRequired();
}
