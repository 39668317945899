import Choices from 'choices.js'

const options = {
  classNames: {
    hiddenState: 'd-none'
  },
  removeItemButton: true,
  shouldSort: false,
}

if (gon.locale === 'fr') {
  options.noResultsText = 'Aucun résultat trouvé'
  options.noChoicesText = 'Aucun choix possible'
  options.itemSelectText = 'Sélectionner'
}

const nodes = []

export default () => {
  const selects = document.querySelectorAll('.choices')

  selects.forEach((element) => {
    const choices = new Choices(element, options)

    nodes.push(choices)
  })
}
