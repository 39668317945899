const NUMBER_REGEX = /[+-]?([0-9]*[.])?[0-9]+/;

const toHoursMinutes = (seconds) => {
  if (!seconds || seconds <= 0) {
    return '-'
  }

  const tokens = []
  const h = Math.floor(seconds / 3600.0)
  const m = Math.round(((seconds / 3600.0) % 1.0) * 60.0)

  if (h > 0) {
    tokens.push(`${h}h`)
  }
  if (h === 0 || m > 0) {
    tokens.push(`${m}m`)
  }

  return tokens.join(' ')
}

function formatHours(hours) {
  const negative = hours < 0;

  const minutes = Math.round((hours % 1) * 60);
  const integerHours = Math.floor(hours)

  const clauses = [];

  if (negative) {
    clauses.push('-')
  }

  if (hours != 0) {
    clauses.push(`${integerHours}h`)
  }

  if (minutes != 0) {
    clauses.push(`${String(minutes).padStart('0', 2)}m`)
  }

  if (clauses.length === 0) {
    clauses.push('0h')
  }

  return clauses.join(' ')
}

function parseHours(timeString) {
  let hours, minutes;

  if (timeString.includes('h')) {
    [hours, minutes] = timeString.split('h');
  } else if (timeString.includes('m')) {
    minutes = timeString;
  } else {
    hours = timeString;
  }

  hours = Number(hours?.match(NUMBER_REGEX)?.[0]);
  minutes = Number(minutes?.match(NUMBER_REGEX)?.[0]);
  if (Number.isNaN(hours)) {
    hours = 0;
  }

  if (Number.isNaN(minutes)) {
    minutes = 0;
  }

  if (hours < 0 || minutes < 0) {
    hours = 0;
    minutes = 0;
  }

  const totalMinutes = Math.round(hours * 60 + minutes);

  hours = totalMinutes / 60;

  return hours
}

export {
  toHoursMinutes,
  formatHours,
  parseHours,
}
