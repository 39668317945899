import { formatHours, parseHours } from '../../packs/shared/util/format';

export default () => {
  document.querySelectorAll('[data-hour-minute-input="true"]').forEach((input) => {
    const dummyInput = document.createElement('input');

    dummyInput.setAttribute('placeholder', '00h 00m')

    input.type = 'hidden';
    input.insertAdjacentElement('afterEnd', dummyInput)
    dummyInput.classList = input.classList;

    if (input.hasAttribute('required')) {
      dummyInput.setAttribute('required', input.getAttribute('required'))
    }

    dummyInput.value = input.value;
    setValues(input, dummyInput);

    dummyInput.addEventListener('change', () => {
      setValues(input, dummyInput);
    });
  });
};

function setValues(input, dummyInput) {
  const { formattedTime, hours } = formatParseHours(dummyInput.value);

  dummyInput.value = formattedTime;
  input.value = hours;
  input.dispatchEvent(new Event('input'));
}

function formatParseHours(timeValue) {
  const timeString = timeValue.toLowerCase().replace(',', '.').trim();

  if (timeString.length < 1) {
    return {
      formattedTime: null,
      hours: null
    }
  }

  const hours = parseHours(timeString);

  return {
    formattedTime: formatHours(hours),
    hours
  };
}
