export default () => {
  document.querySelectorAll('.form .field .help.is-danger').forEach((help) => {
    const field = help.closest('.field');

    help.remove();
    field.appendChild(help);
  });

  /*
    Removes field error messages that are generated by active record validations (see customize_error.rb), when the
    field input values are updated.

    PLEASE REMOVE THIS CODE if/when the need ever comes to implement custom error messages on fields that should
    rendered BEFORE the form submission. This code will otherwise conflict and remove the messages each time
    the input value is updated.
  */
  document.querySelectorAll('input.is-error, select.is-error, textarea.is-error').forEach((input) => {
    input.addEventListener('change', () => {
      const field = input.closest('.field');

      if (!field) {
        return;
      }

      input.classList.remove('is-danger');
      input.classList.remove('is-error');

      field.querySelectorAll('.form-input-hint.is-danger').forEach((hint) => {
        hint.remove()
      })
    })
  })
}
